import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSearchFilterContext } from '../../context/search-filter/search-filter-context';
import { FilterSearch } from './FilterSearch';
import { FilterTree } from './FilterTree';
import { FilterTreeButtons } from './FilterTreeButtons';
import { FilterProfilesSearch } from './FilterProfilesSearch';
import { ProfileTypeFilterSelector } from './ProfileTypeFilterSelector';

const DRAWER_WIDTH = '350px';

const StyledDrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
}));

const StyledFilterDrawerWrap = styled(Box)(({ theme }) => ({
    width: DRAWER_WIDTH,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(4)
}));

const StyledChevronIcon = styled(ChevronLeftIcon)(({ theme }) => ({
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : ''
}));

export const FilterDrawer = () => {
    const { t } = useTranslation();

    const { isFilterDrawerOpen, setIsFilterDrawerOpen } = useSearchFilterContext();

    const handleDrawerClose = () => {
        setIsFilterDrawerOpen(false);
    };

    return (
        <Drawer
            anchor="left"
            open={isFilterDrawerOpen}
            elevation={3}
            ModalProps={{
                onClose: () => setIsFilterDrawerOpen(false),
                disableScrollLock: true,
                BackdropProps: { invisible: true }
            }}
            sx={{
                position: 'relative'
            }}
        >
            <Box sx={{ display: 'block', position: 'relative', overflowX: 'hidden' }}>
                <Paper
                    className="sticky-paper-top"
                    elevation={1}
                    sx={{
                        borderRadius: 0,
                        width: DRAWER_WIDTH,
                        zIndex: 1,
                        WebkitTransform: 'translateZ(1px)',
                        MozTransform: 'translateZ(1px)',
                        transform: 'translateZ(1px)'
                    }}
                >
                    <StyledDrawerHeader>
                        <IconButton
                            onClick={handleDrawerClose}
                            aria-label={t('common:navigation.close_filters_drawer')}
                            title={t('common:navigation.close_filters_drawer')}
                            size="large"
                        >
                            <StyledChevronIcon />
                        </IconButton>
                        <Typography variant="h5" component="div" sx={{ ml: 0.5 }}>
                            {t('common:navigation.filter_listings')}
                        </Typography>
                    </StyledDrawerHeader>
                    <FilterTreeButtons />
                    <FilterProfilesSearch />
                    <ProfileTypeFilterSelector />
                    <FilterSearch />
                </Paper>
                <StyledFilterDrawerWrap>
                    <FilterTree />
                </StyledFilterDrawerWrap>
            </Box>
        </Drawer>
    );
};
