import React, { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import Typography from '@mui/material/Typography';

export const CertifiedCheckMark = ({ sx = {} }: { readonly sx?: SxProps }): ReactNode => {
    const checkmarkSymbol = String.fromCodePoint(0x2713);

    return (
        <Typography
            variant="body1"
            component="span"
            color="info.main"
            sx={{
                mr: 0.5,
                fontSize: '1.4em',
                fontWeight: 'bold',
                ...sx
            }}
        >
            {checkmarkSymbol}
        </Typography>
    );
};
