import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { StyledCheckboxWrap } from '../styled-shared';
import { useSearchFilterContext } from '../../context/search-filter/search-filter-context';
import { CertifiedCheckMark } from '../atoms/CertifiedCheckMark';

export const ProfileTypeFilterSelector = () => {
    const { t } = useTranslation();
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { limitToProfileTypes, setLimitToProfileTypes } = useSearchFilterContext();

    const handlePopoverTriggerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const open = Boolean(popoverAnchorEl);
    const id = open ? 'profile-type-popover' : undefined;

    const handleMakerCheck = useCallback(
        (_e: ChangeEvent<unknown>, checked: boolean) => {
            const profileTypesSet = new Set(limitToProfileTypes);
            checked ? profileTypesSet.add('maker') : profileTypesSet.delete('maker');
            setLimitToProfileTypes(Array.from(profileTypesSet));
        },
        [limitToProfileTypes, setLimitToProfileTypes]
    );

    const handleDealerCheck = useCallback(
        (_e: ChangeEvent<unknown>, checked: boolean) => {
            const profileTypesSet = new Set(limitToProfileTypes);
            checked ? profileTypesSet.add('dealer') : profileTypesSet.delete('dealer');
            setLimitToProfileTypes(Array.from(profileTypesSet));
        },
        [limitToProfileTypes, setLimitToProfileTypes]
    );

    const handlePersonalCheck = useCallback(
        (_e: ChangeEvent<unknown>, checked: boolean) => {
            const profileTypesSet = new Set(limitToProfileTypes);
            checked ? profileTypesSet.add('personal') : profileTypesSet.delete('personal');
            setLimitToProfileTypes(Array.from(profileTypesSet));
        },
        [limitToProfileTypes, setLimitToProfileTypes]
    );

    return (
        <Grid container sx={{ m: 0 }} spacing={1} justifyContent="flex-start">
            <Grid item xs={12}>
                <Typography variant="h6" component="div">
                    {t('common:filters.util.search_by_profile_type')}
                    <>
                        <IconButton onClick={handlePopoverTriggerClick} sx={{ ml: 1, my: 0 }}>
                            <HelpIcon sx={{ color: 'text.secondary', fontSize: '1.4rem' }} />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={popoverAnchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            elevation={3}
                            PaperProps={{
                                elevation: 3,
                                sx: {
                                    padding: '20px',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: 'divider',
                                    borderRadius: '4px'
                                }
                            }}
                        >
                            <Grid container spacing={1} sx={{ maxWidth: '85vw' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="div">
                                        {t('common:filters.util.maker_or_dealer')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <CertifiedCheckMark />
                                        {t('common:filters.util.contact_the_bladebinge_team')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        {t('common:filters.util.reach_thousands_of_customers')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Popover>
                    </>
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={0.5}>
                <Grid item xs={12}>
                    <StyledCheckboxWrap>
                        <Checkbox
                            sx={{ padding: '0 .25rem 0 0' }}
                            id="checkbox-maker-profiles"
                            checked={limitToProfileTypes.includes('dealer')}
                            onChange={handleDealerCheck}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <Typography variant="caption">{t('common:account.profile_types.dealer')}</Typography>
                    </StyledCheckboxWrap>
                </Grid>
                <Grid item xs={12}>
                    <StyledCheckboxWrap>
                        <Checkbox
                            sx={{ padding: '0 .25rem 0 0' }}
                            id="checkbox-dealer-profiles"
                            checked={limitToProfileTypes.includes('maker')}
                            onChange={handleMakerCheck}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <Typography variant="caption">{t('common:account.profile_types.maker')}</Typography>
                    </StyledCheckboxWrap>
                </Grid>
                <Grid item xs={12}>
                    <StyledCheckboxWrap>
                        <Checkbox
                            sx={{ padding: '0 .25rem 0 0' }}
                            id="checkbox-user-profiles"
                            checked={limitToProfileTypes.includes('personal')}
                            onChange={handlePersonalCheck}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <Typography variant="caption">{t('common:account.profile_types.personal')}</Typography>
                    </StyledCheckboxWrap>
                </Grid>
            </Grid>
        </Grid>
    );
};
